import React, { useEffect, useRef } from "react";
import { createCustomEqual } from "fast-equals";
import mapStyle from "./mapStyle";
import "./map.css"

export default function MapComponent({
  center,
  zoom,
  onClick,
  onIdle,
  children,
  style,
  ...options
}) {
  const ref = useRef(null);
  const [map, setMap] = React.useState();

  const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => deepEqual(a, b));

  const useDeepCompareMemoize = (value) => {
    if (!deepCompareEqualsForMaps(value, ref.current)) ref.current = value;
    return ref.current;
  }

  const useDeepCompareEffectForMaps = (
    callback,
    dependencies
  ) => useEffect(callback, dependencies.map(useDeepCompareMemoize));

  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) => window.google.maps.event.clearListeners(map, eventName));

      if (onClick) map.addListener("click", onClick);
      if (onIdle) map.addListener("idle", () => onIdle(map));
    }
  }, [map, onClick, onIdle]);

  useEffect(() => {
    if (ref.current && !map) setMap(new window.google.maps.Map(ref.current, {center, zoom, styles: mapStyle, streetViewControl: false, mapTypeControl: false}));
  }, [ref, map]);

  return (
    <>
    <div ref={ref} id="map" style={style}/>
    {React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        // set the map prop on the child component
        return React.cloneElement(child, { map });
      }
    })}
    </>
  )
}
