import React from "react"

const airplane = require("../images/airplane-icon.png");
const beer = require("../images/beer-mugs.png");
const tree = require("../images/deciduous-tree.png");
const picture = require("../images/framed-picture.png");
const burger = require("../images/hamburger-apple.png");
const coaster = require("../images/roller-coaster.png");
const rainbow = require("../images/rainbow.png");
const carousel = require("../images/carousel-horse.png");
const coffee = require("../images/hot-beverage.png");
const wine = require("../images/wine-glass.png");
const taco = require("../images/taco.png");
const pasta = require("../images/spaghetti.png");
const takeout = require("../images/takeout.png");
const star = require("../images/glowing-star.png");

let openWindow;

const Marker = (options) => {
  const [marker, setMarker] = React.useState();
  const [info, setInfo] = React.useState();

  const {position, title, map, description, content} = options;

  React.useEffect(() => {
    if (!marker) setMarker(new window.google.maps.Marker())
    if (!info) setInfo(new window.google.maps.InfoWindow());

    // remove marker from map on unmount
    return () => {
      if (marker) marker.setMap(null);
    }
  }, [marker]);

  React.useEffect(() => {
    if (marker) {
      let icon
      switch (options.icon) {
        case 'Tree':
          icon = {
            url: tree,
            scaledSize: new window.google.maps.Size(24, 24)
          }
          break;
        case 'Airplane':
          icon = {
            url: airplane,
            scaledSize: new window.google.maps.Size(24, 24)
          }
          break;
        case 'Beer':
          icon = {
            url: beer,
            scaledSize: new window.google.maps.Size(32, 32)
          }
          break;
        case 'Picture':
          icon = {
            url: picture,
            scaledSize: new window.google.maps.Size(24, 24)
          }
          break;
        case 'Burger':
          icon = {
            url: burger,
            scaledSize: new window.google.maps.Size(20, 20)
          }
          break;
        case 'Coaster':
          icon = {
            url: coaster,
            scaledSize: new window.google.maps.Size(28, 28)
          }
          break;
        case 'Rainbow':
          icon = {
            url: rainbow,
            scaledSize: new window.google.maps.Size(24, 24)
          }
          break;
        case 'Carousel':
          icon = {
            url: carousel,
            scaledSize: new window.google.maps.Size(28, 28)
          }
          break;
        case 'Coffee':
          icon = {
            url: coffee,
            scaledSize: new window.google.maps.Size(28, 28)
          }
          break;
        case 'Wine':
          icon = {
            url: wine,
            scaledSize: new window.google.maps.Size(24, 24)
          }
          break;
        case 'Taco':
          icon = {
            url: taco,
            scaledSize: new window.google.maps.Size(24, 24)
          }
          break;
        case 'Pasta':
          icon = {
            url: pasta,
            scaledSize: new window.google.maps.Size(28, 28)
          }
          break;
        case 'Takeout':
          icon = {
            url: takeout,
            scaledSize: new window.google.maps.Size(24, 24)
          }
          break;
        case 'Star':
          icon = {
            url: star,
            scaledSize: new window.google.maps.Size(24, 24)
          }
          break;
        default:
          break;
      }

      marker.setOptions({position, title, map, icon, description});

      marker.addListener("click", () => {
        if (openWindow && openWindow != info) openWindow.close();
        console.log('marker and info', marker, info);
        info.setContent(content);
        info.open({anchor: marker, map});
        openWindow = info;
      });
    }
  }, [marker, options]);

  return null;
};

export default Marker;
