import React from "react"
import Layout from "../components/layout"
import NYC from "../components/nyc"
import SEO from "../components/seo"

const NYCPage = () => (
  <Layout>
    <SEO title="NYC" />
    <NYC />
  </Layout>
)

export default NYCPage
