import React from "react"
import { Wrapper, Status } from "@googlemaps/react-wrapper"

import MapComponent from "./mapcomponent"
import Marker from "./marker"

const locations = require("../assets/hotSpots.json")
const locationNames = Object.keys(locations)
const locationDetails = Object.values(locations)

const isBrowser = () => typeof window !== "undefined"

const isMobile = () => {
  if (isBrowser) {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
      ? true
      : false
  }
  return false
}

const render = (status) => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return null
}

const bodyContent = (title, content) => {
  let contentString =
  '<div id="content">' +
  `<h3 id="infoHeading" class="infoHeading">${title}</h3>` +
  '<div id="bodyContent">' +
  `<p><a class="inline-link" href="${content.directions}" target="_blank">` +
  "Get directions</a></p>" +
  "</div>" +
  "</div>";
  return contentString;
}

export default class NYC extends React.Component {
  ref = React.createRef(null);
  state = {
    clicks: locationDetails.map(location => location.center),
    zoom: 14,
    center: {
      // wburg
      lat: 40.711521744590925,
      lng: -73.95279042455819
    }
  }

  onClick = (e) => {
    if (e.latLng) {
      let newCoords = { lat: e.latLng.lat(), lng: e.latLng.lng() }
      // avoid directly mutating state
      this.setState({clicks: [...this.state.clicks, newCoords]})
    }
  }

  onIdle = (m) => {
    if (m.getZoom()) this.setState({zoom: m.getZoom()});
    if (m.getCenter()) this.setState({center: m.getCenter().toJSON()});
  }

  mapStyle = () => {
    if (isBrowser() && isMobile()) {
      return {
        width: '320px',
        height: '200px',
        display: 'flex'
      }
    }
    return {
      width: '900px',
      height: '450px',
      display: 'flex'
    }
  }

  render() {
    return (
      <div className="main">
        <div className="border-bottom-wrapper">
          <div className="border-bottom">
            <div className="full-width">
              <Wrapper apiKey="AIzaSyAnSHxLDWGCyXGiT_yyOwwmfuaHqoNVNJ8" render={render}>
                <MapComponent
                  onClick={this.onClick}
                  onIdle={this.onIdle}
                  zoom={this.state.zoom}
                  center={this.state.center}
                  // className="map-width"
                  style={this.mapStyle()}
                >
                  {locationDetails.map((location, i) => (
                    <Marker key={i} position={location.center} icon={location.icon} title={locationNames[i]} content={bodyContent(locationNames[i], location.content)} onFocusOut={this.onFocusOut}/>
                  ))}
                </MapComponent>
              </Wrapper>
            </div>
          </div>
        </div>
        <div className="full-width">
          <p style={{marginTop: '20px'}}>
            click on a marker for directions to some our favorite spots!
          </p>
        </div>
      </div>
    )
  }
}
